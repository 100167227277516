<template>
    <el-dialog
        :visible.sync="show_modal"
        width="90%"
        append-to-body
        modal-append-to-body
        custom-class="prb-fields-dialog"
        :close-on-click-modal="false">
            
        <template slot="title">
            <div>
                <div class="tl-dialog-title">
                    <span>{{title}}</span>
                </div>
            </div>
        </template>

        <div class="prb-fields-wrapper">
            <div v-for="section in list" :key="section.id"  class="prb-fields-section">
                <div class="prb-fields-section_title">{{section. title}}</div>
                <div class="prb-fields-section_wrapper">
                    <div v-for="field in section.children" 
                        :key="field.id" 
                        @click="add_field(field)"
                        class="prb-fields-item"
                        v-bind:class="{ active: is_active_field(field) }">
                        {{field.title}}
                    </div>
                </div>
            </div>
        </div>

        <div class="tl-form-buttons">
            <div class="spacer" />
            <el-button @click="show_modal = false">Cancel</el-button>
            <el-button type="primary" @click="save_changes">Save</el-button>
        </div>
    </el-dialog>
</template>

<script>1
import XEUtils from 'xe-utils'
export default {
    props: {
       open: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: false
        },
        selected: {
            required: true
        },
        fields: {
            required: true
        },
        call_type: {
            required: true,
            type: String
        },
    },

    data(){
        return {
            show_modal : false,
            selected_fields: []
        }
    },

    computed: {
        list(){
            return XEUtils.toArrayTree(this.fields, { key: "id", parentKey: "parent_id" })
        }
    },

    methods: {
        is_active_field(field){
            let exist = this.selected_fields.find(x => x === field.id)
            return (exist) ? true : false
        },

        add_field(field) {
            if (this.call_type === 'filter')
                this.add_filter_field(field);
            else
                this.add_not_filter_field(field);
        },

        add_filter_field(field){
            if (this.is_active_field(field)) {
                let index = this.selected_fields.findIndex(x => x === field.id)
                let field_data = this.fields.find(x => x.id === field.id)
                if (!field_data.obligatory)
                    this.selected_fields.splice(index, 1);
                else
                    this.$message({ type: 'error', message: 'Field ' + field_data.title + ' is obligatory and cannot be removed from filter' })
            }
            else if (!this.selected_fields.includes('client_id') && ['portfolio_id', 'strategy_id'].includes(field.id)) {
                this.$message({ type: 'error', message: 'Field ' + field_data.title + ' cannot be added without adding Client field' })
            }
            else {
                this.selected_fields.push(field.id)
            }
        },

        add_not_filter_field(field){
            if (this.is_active_field(field)){
                let index = this.selected_fields.findIndex(x => x === field.id)
                this.selected_fields.splice(index, 1);
            }
            else {
                this.selected_fields.push(field.id)
            }
        },

        save_changes() {
            this.$emit('selected-fields', this.selected_fields)
        }
    },

    watch: {
        open(val){
            this.show_modal = val;
            if (val) this.selected_fields = this.selected;
            else this.selected_fields = [];
        },
        show_modal(val){
            if (!val) this.$emit('closed');
        }
    }
}
</script>
