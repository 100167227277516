<template>
    <div class="prb-settings">
        <div class="prb-settings-title">Sort by</div>
        <div class="prb-settings-body" v-loading="loading">
            <div  class="prb-settings-wrapper">
                <draggable v-model="sortby" draggable=".prb-settings-item" @change="change_order" class="prb-settings-wrapper">
                    <div 
                        v-for="(item, index) in sortby" 
                        class="prb-settings-item">
                        <div class="prb-settings-item_sum" >
                            <el-button type="text" class="prb-settings-item_sum-btn active" @click="set_sort_dir(item)">
                                <i class="el-icon-top" v-if="item.dir === 'desc'"></i>
                                <i class="el-icon-bottom" v-else></i>
                            </el-button>
                        </div>
                        <div class="prb-settings-item_title">{{item.field_data.title}}</div>
                        <div class="prb-settings-item_close">
                            <el-button type="text" class="prb-settings-item_sum-btn" @click="sortby.splice(index, 1);">
                                <i class="el-icon-close"></i>
                            </el-button>
                        </div>
                    </div>
                </draggable>
                <el-button type="text" class="prb-settings-add" @click="open_fields_window">
                    <i class="el-icon-circle-plus-outline"></i>
                </el-button>
            </div>
        </div>

        <ReportFields 
            :open="fields_window_open" 
            title="Add sorting"
            @closed="fields_window_open = false"
            @selected-fields="handle_selected_fields"
            :selected="selected_fields"
            :fields="fields"
            call_type="sortby"  />
    </div>
</template>

<script>
import ReportFields from './ReportFields'
import draggable from "vuedraggable";
import {firstBy} from "thenby";
export default {
    props: {
        fields: {
            required: true
        },
        loading: {
            default: false
        }
    },
    components: { draggable, ReportFields },

    data(){
        return {
            sortby: [],
            fields_window_open: false,
            selected_fields: [],
            is_clearing: false,
        }
    },

    methods: {
        load_sortby(sortby){
            this.$nextTick(() => {
                sortby.forEach(c => {
                    c.field_data = this.fields.find(fd => fd.id === c.field)
                })
                sortby.sort(firstBy(function (v) { return v.field_data.order; }, {direction:"asd"}))
                this.sortby = sortby
            });
        },
        open_fields_window(){
            this.selected_fields = []
            this.sortby.forEach(f => {
                this.selected_fields.push(f.field)
            })
            this.fields_window_open = true
        },
        handle_selected_fields(fields){
            this.fields_window_open = false
            this.sortby = this.sortby.filter(el => {
                return fields.some((f) => {
                    return f.id === el.field
                });
            })
            fields.forEach(f => {
                let exist = this.sortby.find(item => item.field == f)
                if (!exist) {
                    let field_data = this.fields.find(fd => fd.id === f)
                    this.sortby.push({
                        field      : field_data.id,
                        field_data : field_data,
                        dir        : 'asc',
                        order      : 0,
                    })
                }
            })
            this.change_order()
        },
        set_sort_dir(item){
            if (item.dir === 'asc')
                this.$set(item, 'dir', 'desc');
            else
                this.$set(item, 'dir', 'asc');
        },
        change_order(){
            let order = 0
            this.sortby.forEach(c => {
                order++;
                c.order = order
            })
        },
        clear_data(){
            this.is_clearing = true
            this.sortby = []
            this.fields_window_open = false
            this.selected_fields = []
            this.$nextTick(() => this.is_clearing = false)
        }
    },

    watch: {
        sortby: {
            deep: true,
            handler(val){
                if(!this.is_clearing) this.$emit('update', val)
            }
        },

    }
}
</script>
